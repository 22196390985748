import { TABS } from "./slices/ui";
import type { LiveStore } from "./useLiveStore";

// Settings
export const selectSettings = (state: LiveStore) => state.settings || {};
export const selectAudioInputId = (state: LiveStore) =>
  state.settings?.audioInputId;
export const selectAudioOutputId = (state: LiveStore) =>
  state.settings?.audioOutputId;
export const selectVideoInputId = (state: LiveStore) =>
  state.settings?.videoInputId;
export const selectBlurEnabled = (state: LiveStore) =>
  state.settings?.blurEnabled;
export const selectMirrorEnabled = (state: LiveStore) =>
  state.settings?.mirrorEnabled;
export const selectMicrophoneEnabled = (state: LiveStore) =>
  state.settings?.microphoneEnabled;
export const selectCameraEnabled = (state: LiveStore) =>
  state.settings?.cameraEnabled;
export const selectNoiseSuppression = (state: LiveStore) =>
  state.settings?.noiseSuppression;
export const selectGridLayoutPageSize = (state: LiveStore) =>
  state.settings?.gridLayoutPageSize;
export const selectSpeakerLayoutPageSize = (state: LiveStore) =>
  state.settings?.speakerLayoutPageSize;
export const selectInfinityMirrorAlertDisabled = (state: LiveStore) =>
  state.settings?.infinityMirrorAlertDisabled;

// UI
export const selectUI = (state: LiveStore) => state.ui;
export const selectIsSidebarOpen = (state: LiveStore) =>
  state.ui?.isSidebarOpen;
export const selectIsChatSelected = (state: LiveStore) =>
  state.ui?.currentTab === TABS.CHAT;
export const selectIsParticipantsSelected = (state: LiveStore) =>
  state.ui?.currentTab === TABS.PARTICIPANTS;
export const selectIsQASelected = (state: LiveStore) =>
  state.ui?.currentTab === TABS.QA;

// Session
export const selectSession = (state: LiveStore) => state.session || {};
export const selectSpectators = (state: LiveStore) =>
  state.session?.spectators || {};

// QA
export const selectQA = (state: LiveStore) => state.qa || {};
export const selectQASession = (state: LiveStore) => state.qa.session;
export const selectQAQuestions = (state: LiveStore) => {
  const upvotedQuestionIds = state.qa.upvotedQuestionIds || [];
  return (
    state.qa.questions?.map(question => ({
      ...question,
      upvoted_by_current_community_member: upvotedQuestionIds.includes(
        question.id,
      ),
    })) || []
  );
};
export const selectQAQuestionById = (state: LiveStore, id: number) => {
  const upvotedQuestionIds = state.qa.upvotedQuestionIds || [];
  const question = state.qa.questions.find(q => q.id === id);
  return question
    ? {
        ...question,
        upvoted_by_current_community_member: upvotedQuestionIds.includes(id),
      }
    : null;
};
export const selectQAReplyingQuestion = (state: LiveStore) => {
  const replyingQuestionId = state.qa.replyingQuestionId;
  return replyingQuestionId
    ? selectQAQuestionById(state, replyingQuestionId)
    : null;
};
export const selectQAReplies = (state: LiveStore) => state.qa.replies || [];
export const selectQAActions = (state: LiveStore) => state.qaActions || {};
export const selectQAFilterByStatus = (state: LiveStore) =>
  state.qa.filterByStatus;
export const selectQASortBy = (state: LiveStore) => state.qa.sortBy;

// Spotlight
export const selectSpotlight = (state: LiveStore) => state.spotlight || {};
export const selectSpotlightActions = (state: LiveStore) =>
  state.spotlightActions || {};
export const selectSpotlightItems = (state: LiveStore) =>
  state.spotlight.items || [];
export const selectFirstSpotlightItem = (state: LiveStore) =>
  state.spotlight.items?.[0];
